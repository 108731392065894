const currentUrl = window.location.href;

let BASE_URL_DEV = '';

if (currentUrl.includes("empatemilionario.com.br")) {
  BASE_URL_DEV = 'https://apy.empatemilionario.com.br/api/v1'
} else if (currentUrl.includes("devaribox.co")) {
  BASE_URL_DEV = 'https://apy.empatemilionario.com.br/api/v1'
} else if (currentUrl.includes(":300")) {
  BASE_URL_DEV = "https://apy.empatemilionario.com.br/api/v1";
}

export const AUTH_URL = `${BASE_URL_DEV}/authenticate/`;
export const MATCH_URL = `${BASE_URL_DEV}/match/`;
export const INCOMMING_MATCH_URL = `${MATCH_URL}next`;
export const PATCH_PERFIL_URL = `${BASE_URL_DEV}/user/`;
export const PATCH_FILTROS_TS_URL = `${BASE_URL_DEV}/filter/`;
export const GET_FILTROS_TS = `${BASE_URL_DEV}/`
export const GET_OURO_PO = `${BASE_URL_DEV}/match/gold`
//export const POST_FILTROS_TS_URL = `${BASE_URL_DEV}/filter/`;