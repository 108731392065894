import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';

import {useAuth} from './contexts/auth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));

const App = () => {

  const {signed} = useAuth();

  return (
    <HashRouter>
        <React.Suspense fallback={loading}>
            {signed ? 
              <Switch>
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> 
              </Switch>
              :
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Redirect from="/" to="/login" />
              </Switch>
            }
        </React.Suspense>
    </HashRouter>
  );
}

export default App;
