import React, { createContext, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Notification from '../components/notifications';
import { signInService } from '../services/auth';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState({});

    useEffect(() => {
        const localUser = JSON.parse(localStorage.getItem('@tips/user'));
        if(localUser){
            axios.defaults.headers.common['Authorization'] = `Token ${localUser.token}`;
            setUser(localUser);
        }

    }, [])

    const updatePerfil = (formData) => {
        if(formData !== null){
            setUser(formData)
            localStorage.setItem('@tips/user', JSON.stringify(formData))
            //console.log(data);
        }
    }

    const updateFiltro = (data) => {
        if(data !== null){
            const profile = { ...user, profile_filter: data }
            setUser(profile)
            localStorage.setItem('@tips/user', JSON.stringify(profile))
        }
    }

    const signIn = async (username, password) => {
        const response = await signInService(username, password);
        if(response.status == 200) {
            setUser(response.data);
            localStorage.setItem('@tips/user', JSON.stringify(response.data));
            axios.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;
        } else {
            Notification("Ops", "Usuário ou senha inválidos", 'error');
        }
    }

    const signOut = () => {
        setUser({});
        localStorage.removeItem('@tips/user');
    }
    
    return (
        <AuthContext.Provider 
        value={{
            signed: (user && user.name) ? true : false,
            user,
            signIn,
            signOut,
            updatePerfil,
            updateFiltro
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
}

export default AuthContext