import axios from 'axios';
import { AUTH_URL } from './config';

export const signInService = async (username, password) => {
  try {
    const response = await axios.post(AUTH_URL, {username, password});
    return response;
  } catch (error) {
    console.log(error)
    return error
  }
}